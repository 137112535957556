import { DashboardCard } from '@/components/DashboardCard';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import PageContainer from '@/components/PageContainer';
import { InvestorReportsDetailsData } from '@/pages/InvestorReporting/utils/investorReports';
import { faArrowLeft, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { InvestorReportsInfo } from '@/pages/InvestorReporting/components/InvestorReportsInfo';
import { Spinner } from '@/components/Spinner';
import { useMemo, useEffect, useState } from 'react';
// eslint-disable-next-line
import { mapApiInvestorReportsDataToTableData } from '@/pages/InvestorReporting/components/Investors/investorReporting.util';
import { Card } from 'react-daisyui';
import useInvestorReportsData from '@/hooks/useInvestorReportsData';
import { get } from '@/utils/queries';
import { Client, InvestorReportDTO } from '@types';
import { IUserContext, useUserContext } from '@/context/UserContext';
// eslint-disable-next-line max-len
import { InvestorReportsDetailedViewV2 } from '@/pages/InvestorReporting/components/Investors/v2/InvestorReportDetailedViewV2';
import { setInvestorReportSignal } from '@/pages/InvestorReporting/utils/investorReportDataSignal';

interface QueryData {
  clientId: number;
  reportId: number;
  firmId: number | null;
  userId: number | null;
}

interface Props {
  removeImpersonatingAccountIdForInvestors?: boolean;
}

export function InvestorReportsClientDetailedView({
  removeImpersonatingAccountIdForInvestors,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    auth,
    companyName,
    impersonatingAccountId,
    setimpersonatingAccountId,
  }: IUserContext = useUserContext();

  const queryParams = new URLSearchParams(location.search);
  const firmId = queryParams.get('firmId');
  const userId = queryParams.get('userId');
  const retrievedClientId = queryParams.get('clientId');
  const reportId = queryParams.get('reportId');
  const clientNameFromQuery = queryParams.get('clientName') || '';
  const showPastUpdates = queryParams.get('showPastUpdates');
  const pendingReportId = queryParams.get('pendingReportId');

  useEffect(() => {
    if (
      (!retrievedClientId || !reportId || (!firmId && !userId)) &&
      !pendingReportId
    ) {
      navigate('/i-new-reporting');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrievedClientId, reportId, firmId, userId]);

  useEffect(() => {
    if (
      removeImpersonatingAccountIdForInvestors &&
      setimpersonatingAccountId &&
      auth?.isInvestor
    ) {
      setimpersonatingAccountId(null);
    }
  }, [
    removeImpersonatingAccountIdForInvestors,
    setimpersonatingAccountId,
    auth?.isInvestor,
  ]);

  queryParams.delete('firmId');
  queryParams.delete('userId');
  queryParams.delete('clientId');
  queryParams.delete('reportId');
  queryParams.delete('pendingReportId');
  const newUrl = `${window.location.origin}/i-new-reporting-details`;
  window.history.replaceState({}, '', newUrl);
  const [data, setData] = useState<QueryData>({
    clientId: Number(retrievedClientId),
    reportId: Number(reportId),
    firmId: firmId ? Number(firmId) : null,
    userId: userId ? Number(userId) : null,
  });

  const { getInvestorReportsTemplateData } = useInvestorReportsData();

  const {
    data: reportData,
    isValidating: isReportsLoading,
    error: reportsError,
    mutate: mutateReports,
  } = get<InvestorReportDTO[]>(
    impersonatingAccountId
      ? `investorReports?clientId=${impersonatingAccountId}`
      : 'investorReports'
  );

  const {
    data: reportTemplateData,
    isLoading: isLoadingTemplates,
    error: errorTemplates,
    mutate: mutateTemplates,
  } = getInvestorReportsTemplateData(
    data?.clientId || 0,
    data?.userId || 0,
    data?.firmId || 0
  );

  const {
    data: allInvestorReports,
    isValidating: isLoadingAllReports,
    error: allReportsError,
    mutate: mutateAllReports,
  } = get<InvestorReportDTO[]>(
    impersonatingAccountId
      ? `investorReports/all?clientId=${impersonatingAccountId}`
      : 'investorReports/all'
  );

  const { data: companyData } = auth?.isClient
    ? get<Client[]>(`account/${auth.clients[0].id}`)
    : get<Client[]>('account/list');

  const refreshData = async (newReportId: number) => {
    setData({ ...data, reportId: newReportId });
    await mutateReports();
    await mutateTemplates();
    await mutateAllReports();
  };

  const refreshTemplates = async () => {
    await mutateTemplates();
  };

  const clientId = useMemo(() => data?.clientId || 0, [data?.clientId]);

  const investorReportsData: InvestorReportsDetailsData = useMemo(() => {
    if (!reportData || !reportTemplateData) {
      return {
        investor_name: '',
        investor_reports: [],
        selected_investor_report: undefined,
      };
    }

    let filteredReports: InvestorReportDTO[] = [];

    // if past updates
    if (!!clientNameFromQuery || !!showPastUpdates) {
      filteredReports = (allInvestorReports || []).filter(
        (report) => report.id === data.reportId
      );
    } else {
      filteredReports = reportData.filter(
        (report) =>
          report.firm_id === data.firmId &&
          report.user_id === data.userId &&
          report.client_id === data.clientId
      );
    }

    const mappedData = mapApiInvestorReportsDataToTableData(
      filteredReports,
      reportTemplateData
    );
    setInvestorReportSignal({
      reportData,
      isReportsLoading,
      reportsError,
      mutateReports,
    });
    return {
      investor_name: mappedData[0]?.investor_name,
      investor_reports: filteredReports,
      selected_investor_report: filteredReports.find(
        (report) => report.id === data.reportId
      ),
    };
  }, [
    reportData,
    reportTemplateData,
    allInvestorReports,
    data.clientId,
    data.firmId,
    data.reportId,
    data.userId,
    mutateReports,
    isReportsLoading,
    reportsError,
    clientNameFromQuery,
    showPastUpdates,
  ]);

  const onClickBackToList = () => {
    if (clientNameFromQuery) {
      navigate(
        `/i-new-reporting?clientName=${encodeURIComponent(clientNameFromQuery)}`
      );
    } else if (showPastUpdates?.toLocaleLowerCase() === 'true') {
      navigate('/i-new-reporting?showPastUpdates=true');
    } else {
      navigate('/i-new-reporting');
    }
  };

  if (
    (!reportData && !isReportsLoading) ||
    reportsError ||
    (!reportTemplateData && !isLoadingTemplates) ||
    errorTemplates ||
    (!allInvestorReports && isLoadingAllReports) ||
    allReportsError
  ) {
    navigate('/i-reporting');
    return null;
  }

  if (isReportsLoading || isLoadingTemplates) {
    return (
      <Card.Body className='w-full h-32 flex items-center justify-center'>
        <Spinner className='opacity-50' />
      </Card.Body>
    );
  }

  const props = {
    ...(companyName && { companyName }),
    ...(companyData && { companyData }),
  };

  return (
    <PageContainer name='investor-report-details'>
      <DashboardWrapper {...props}>
        <>
          <div className='p-4'>
            <div className='flex w-full justify-between items-center mb-4'>
              <h1 className='text-lg font-bold uppercase'>
                <FontAwesomeIcon
                  icon={faChartLine}
                  size='lg'
                  className='mr-4 text-white'
                />
                Investor Reporting Detail
              </h1>
            </div>
          </div>
          <DashboardCard className='p-4' cardBodyclassName='p-0'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
            <div
              className='flex justify-between items-center'
              onClick={onClickBackToList}
              role='button'
            >
              <h2 className='text-mg font-bold uppercase flex'>
                <FontAwesomeIcon
                  color='#d3d3d3'
                  icon={faArrowLeft}
                  size='lg'
                  className='p-4 cursor-pointer'
                />
                <span className='items-center content-center'>
                  Go back to {auth?.isInvestor ? 'Company' : 'Investor'} list
                </span>
              </h2>
            </div>
            {auth?.isClient || (impersonatingAccountId && auth?.isAdmin) ? (
              <InvestorReportsInfo
                clientId={clientId}
                investorReportsData={investorReportsData}
                refreshData={refreshData}
                investorReportTemplatesData={reportTemplateData || []}
                pendingReportId={
                  pendingReportId ? Number(pendingReportId) : undefined
                }
              />
            ) : null}
            {auth?.isInvestor ? (
              <InvestorReportsDetailedViewV2
                clientId={clientId}
                investorReportsData={investorReportsData}
                refreshData={refreshData}
                investorReportTemplatesData={reportTemplateData || []}
                refreshTemplates={refreshTemplates}
                isInPastUpdatesMode={!!clientNameFromQuery || !!showPastUpdates}
                pendingReportId={
                  pendingReportId ? Number(pendingReportId) : undefined
                }
              />
            ) : null}
          </DashboardCard>
        </>
      </DashboardWrapper>
    </PageContainer>
  );
}

export default InvestorReportsClientDetailedView;
