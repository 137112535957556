import { InvestorReportCardDTO, Client, InvestorReportDTO } from '@types';
import { useMemo, useState, useEffect } from 'react';
import { TableSort } from '@/types';
import { getNewSortStateTable } from '@/utils/tableSort';
import { patch, post } from '@/utils/api';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import {
  columnsForOpenRequests,
  OpenRequestsTableData,
  mapOpenCardsDataToTableData,
} from '@/pages/InvestorReporting/components/Clients/investorReportingClient.util';
import { GenericTable } from '@/components/Table/Table';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { GenericModal } from '@/components/Modal';
import { InvestorReportAnswer } from '@/pages/InvestorReporting/components/Clients/InvestorReportAnswer';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { Spinner } from '@/components/Spinner';
import { ReportStatuses } from '../../../../../../../../shared/constants';

interface OpenCardsForClientsProps {
  openCards: InvestorReportCardDTO[];
  openRequests: InvestorReportDTO[];
  recordsPerPage: number;
  selectedPage: number;
  onPageSelect: (page: number) => void;
  onSelectRowsChange: (num: number) => void;
  searchText: string;
  refreshData: () => Promise<void>;
}

export function OpenCardsForClients({
  openCards,
  openRequests,
  recordsPerPage,
  selectedPage,
  onPageSelect,
  onSelectRowsChange,
  searchText,
  refreshData,
}: OpenCardsForClientsProps) {
  const { auth }: IUserContext = useUserContext();
  const [sort, setSort] = useState<TableSort<OpenRequestsTableData>>({
    field: 'action' as keyof OpenRequestsTableData,
    asc: true,
  });
  const [completedReport, setCompletedReport] = useState<
    InvestorReportDTO | undefined
  >(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [requestToAnswer, setRequestToAnswer] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [dataRequestToClose, setDataRequestToClose] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [isDataRefreshed, setIsDataRefreshed] = useState(false);

  const filteredCards = useMemo(() => {
    return openCards.filter((card) =>
      card.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [openCards, searchText]);

  const sortedCards = useMemo(() => {
    return filteredCards.sort((c1, c2) => {
      if (sort.field === 'card_name') {
        return sort.asc
          ? c1.title.localeCompare(c2.title)
          : c2.title.localeCompare(c1.title);
      }
      if (sort.field === 'created_at') {
        const time1 = new Date(c1.created_at || '').getTime();
        const time2 = new Date(c2.created_at || '').getTime();
        return sort.asc ? time1 - time2 : time2 - time1;
      }
      return 0;
    });
  }, [filteredCards, sort]);

  const sortBy = (fieldName: keyof OpenRequestsTableData) => {
    const newSort = getNewSortStateTable(fieldName, sort);
    setSort(newSort);
  };

  const selectedDataRequest = (card: InvestorReportCardDTO) => {
    setRequestToAnswer(undefined);
    setTimeout(() => {
      setRequestToAnswer(card);
    }, 50);
  };

  useEffect(() => {
    if (isDataRefreshed) {
      setIsDataRefreshed(false);
    }
  }, [isDataRefreshed]);

  const answerRequest = (request: InvestorReportCardDTO) => {
    selectedDataRequest(request);
  };

  const markAsClosed = (request: InvestorReportCardDTO) => {
    setRequestToAnswer(undefined);
    setDataRequestToClose(request);
  };

  const tableData = useMemo(() => {
    return mapOpenCardsDataToTableData(
      sortedCards,
      answerRequest,
      markAsClosed
    ) as OpenRequestsTableData[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedCards]);

  const getCompletedReport = (cardToUpdate: InvestorReportCardDTO) => {
    const report = openRequests.find(
      (r) => r.id === cardToUpdate.investor_report_id
    );

    if (!report) return undefined;

    const isReportNotClosed = report.status !== ReportStatuses.closed;
    const allCardAnswered =
      (report?.investor_report_cards || []).filter(
        (card) =>
          card.id !== cardToUpdate.id &&
          (card.status === ReportStatuses.completed ||
            card.status === ReportStatuses.closed)
      ).length > 1;

    return isReportNotClosed && allCardAnswered ? report : undefined;
  };

  const handleRequestChangeStatus = async (
    card: InvestorReportCardDTO,
    status: ReportStatuses.closed | ReportStatuses.open
  ) => {
    setIsSaving(true);
    let reportCompleted: InvestorReportDTO | undefined;
    try {
      await patch(
        `investorReports/investorReportsCard/client/${Number(
          auth?.clients[0].id
        )}/status/${card?.id as number}`,
        {
          status,
        }
      );
      alertMessageSuccess('Card updated successfully');

      reportCompleted = getCompletedReport(
        dataRequestToClose as InvestorReportCardDTO
      );
      setDataRequestToClose(undefined);
      await refreshData();
      setIsDataRefreshed(true);
    } catch {
      alertErrorMessage('Failed to update card, please try again later');
    }
    setIsSaving(false);
    if (reportCompleted) {
      setCompletedReport(reportCompleted);
    }
  };

  const onHandleMarkAsClosed = async () => {
    await handleRequestChangeStatus(
      dataRequestToClose as InvestorReportCardDTO,
      ReportStatuses.closed
    );
  };

  const onHandleRequestUpdate = async () => {
    const reportCompleted = getCompletedReport(
      requestToAnswer as InvestorReportCardDTO
    );
    await refreshData();
    setRequestToAnswer(undefined);
    setIsDataRefreshed(true);
    if (reportCompleted) {
      setCompletedReport(reportCompleted);
    }
  };

  const onHandleSendUpdates = async (notifyInvestors: boolean) => {
    setIsSaving(true);
    try {
      await post(
        `investorReports/closeReport/${completedReport?.id as number}`,
        {
          notifyInvestors,
        }
      );
      await refreshData();
      alertMessageSuccess('Report updated successfully');
    } catch {
      alertErrorMessage('Failed to send updates, please try again later');
    }
    setCompletedReport(undefined);
    setIsSaving(false);
  };

  return (
    <>
      {dataRequestToClose ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={onHandleMarkAsClosed}
          onCloseAction={() => setDataRequestToClose(undefined)}
          showModal={!!dataRequestToClose}
          modalMessage='Are you sure you want to mark this card as closed?'
        />
      ) : null}

      {requestToAnswer ? (
        <GenericModal isOpen onClose={() => {}} isFullWidth>
          <InvestorReportAnswer
            investorReportsCard={requestToAnswer}
            client={auth?.clients[0] as Client}
            setSpinner={(value) => {
              setIsSaving(value);
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onCardUpdated={() => onHandleRequestUpdate()}
            setFileToPreview={() => {}}
            isFullWidth
          />
        </GenericModal>
      ) : null}

      {completedReport ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={async () => onHandleSendUpdates(true)}
          onCloseAction={() => setCompletedReport(undefined)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onCancelAction={async () => onHandleSendUpdates(false)}
          showModal={completedReport !== undefined}
          // eslint-disable-next-line max-len
          modalMessage={`You have completed all open requests for ${
            completedReport?.firm
              ? completedReport?.firm?.firm_name
              : `${completedReport.user?.first_name as string} ${
                  completedReport.user?.last_name as string
                }`
          } for ${
            completedReport?.period
          }. Click 'Confirm' to notify the investor or 'Cancel' to skip notification.`}
        />
      ) : null}
      <div
        className={`${isSaving ? 'spinner-container' : 'hidden'} mb-3`}
        id='spinner-total'
        data-testid='spinner-open-requests'
      >
        <Spinner className='spinner' />
      </div>

      <GenericTable
        className='w-full mb-2'
        tableName='Investor Clients'
        data={tableData}
        columns={columnsForOpenRequests}
        sortBy={sortBy}
        sort={sort}
        recordsPerPage={recordsPerPage}
        selectedPage={selectedPage}
        onPageSelect={onPageSelect}
        onSelectRowsChange={onSelectRowsChange}
      />
    </>
  );
}
