import FrequencySelector from '@/pages/InvestorReporting/components/Investors/FrequencySelector';
import { InvestorReportTemplateDTO } from '@types';
import { useEffect, useState } from 'react';
import RequestBuilder from '@/pages/InvestorReporting/components/modals/RequestBuilder';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { Badge } from 'react-daisyui';
import { ReportsFrequencies } from '../../../../../shared/constants';

export default function PendingTemplates({
  pendingTemplateId,
  templates,
  onSave,
}: {
  pendingTemplateId: number;
  templates: InvestorReportTemplateDTO[];
  onSave: () => Promise<void>;
}) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext();
  const [template, setTemplate] = useState<InvestorReportTemplateDTO>(
    templates.find(
      (temp) => temp.id === pendingTemplateId
    ) as InvestorReportTemplateDTO
  );
  const [selectedFrequency, setSelectedFrequency] =
    useState<ReportsFrequencies>(template.frequency);

  useEffect(() => {
    const newTemplate = templates.find(
      (temp) => temp.frequency === selectedFrequency
    ) as InvestorReportTemplateDTO;
    setTemplate(newTemplate);
  }, [selectedFrequency, pendingTemplateId, templates]);

  const enabledFrequencies = (reports: InvestorReportTemplateDTO[]) => {
    return reports.map((rep) => rep.frequency);
  };

  return (
    <>
      <div className='pl-4 flex'>
        <FrequencySelector
          selectedFrequency={selectedFrequency}
          enabledFrequencies={enabledFrequencies(templates)}
          onChangeFrequency={setSelectedFrequency}
        />
      </div>

      {auth?.isInvestor ? (
        <div className='w-[50%]'>
          <RequestBuilder onSave={onSave} editTemplate={template} />
        </div>
      ) : null}

      {auth?.isClient || (auth?.isAdmin && impersonatingAccountId) ? (
        <div className='flex flex-wrap w-[50%]'>
          {template.investor_report_card_templates?.map((card) => (
            <div
              key={`card-${card.title}`}
              className={`items-center my-2 mx-2 shadow card rounded-lg 
            w-[45%] justify-center bg-gradient-to-r p-[1px] from-[#00a089] to-[#ff0080]`}
            >
              <div
                key={`card-${card.title}`}
                className='bg-[#1b1b1c] shadow-inner rounded h-full w-full content-center'
              >
                <div className='mt-4 mx-4 text-center '>
                  <span className='font-bold'>{card.title}</span>
                  <div>
                    <span className='font-bold text-sm'>
                      Status:
                      <Badge color='error' size='md' className='ml-2'>
                        Pending
                      </Badge>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}
